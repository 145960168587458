.index-list-row {
    display: flex;
    width: 100%;
    margin-top: 1rem;
}
.index-list-column-one {
    width: 40%;
}
.index-list-column-two {
    display: flex;
    width: 60%;
    scroll-behavior: smooth;

    h5 {
        font-family: Raleway-Regular;
        margin-right: 0.3rem;
        font-size: 0.8rem;
    }

    h3 {
        font-family: Raleway-Regular;
        font-size: 1rem;
        line-height: 2.5;
        font-weight: 300;
    }
}
