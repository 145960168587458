.desktop-header-container {
    visibility: hidden;
}

@media (min-width: 950px) {
    .desktop-header-container {
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: $bg-color;
        height: 60px;
        z-index: 300;

        .sidebar-button {
            display: inline-block;
            position: relative;
            padding: $base-padding;
            color: $secondary-dark;
            height: 24px;

            .btn-icon {
                height: 24px;
                width: auto;
                padding-right: 0.5rem;
            }
            .sidebar-btn-tooltip {
                position: absolute;
                left: 100%;
                top: 50%;
                background-color: $secondary;
                border-radius: 4px;
                color: $white;
                height: 100%;
                width: 100%;
                padding: 3px;
                z-index: 200;
            }
        }
    }
}
