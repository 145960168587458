.hero {
    display: flex;
    justify-content: center;
    margin: auto;
    position: relative;
    height: 86dvh;
    background-color: $bg-color;

    .text-wrapper {
        position: absolute;
        top: 22%;
        padding: $base-padding;
        color: $secondary-dark;
    }
}
