.navigation-drawer {
    display: flex;
    flex-direction: column;
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 200;
    transform: translateX(100%);
    transition: transform 0.5s ease-out;

    &.open {
        transform: translateX(3%);
        box-shadow: 0 0 0 1600px rgba(0, 0, 0, 0.65);
    }

    .drawer-header {
        margin-left: auto;

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $primary;
            border: none;
            height: 24px;
            width: 24px;
            border-radius: 50%;
            margin: 1.5rem;
            box-shadow: $shadow 0px 2px 8px 0px;
        }
        .close-icon {
            color: $secondary-dark;
            width: 24px;
            height: 24px;
        }
    }

    .drawer-content {
        overflow: scroll;
        height: 100%;
        .links {
            display: flex;
            flex-direction: column;
            padding-top: 20%;
            padding-bottom: 10%;
            ul {
                margin-left: auto;
            }
            li {
                text-align: end;
                padding: 0.5rem $base-padding * 3;
            }
            a {
                color: $primary-dark;
            }
        }
    }
}
