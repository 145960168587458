.sidebar-container {
    visibility: hidden;
    width: 0;
    height: 0;
}

@media (min-width: 950px) {
    .sidebar-container {
        visibility: visible;
        height: inherit;
        width: 18rem;
        margin-top: -60px;
        background-color: $white;
        z-index: 300;

        .sidebar-wrapper {
            position: relative;

            width: 18rem;
            padding: $base-padding;

            .close-sidebar-button {
                position: fixed;
                top: 2%;
                left: 1%;
                height: 24px;
                .btn-icon {
                    height: 24px;
                    width: auto;
                }
            }

            .sidebar-content {
                position: fixed;
                top: 10%;
                left: 0;
                width: 18rem;

                .list-item {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    margin: auto;

                    h5 {
                        padding-right: 3px;
                        margin-bottom: 4px;
                    }
                }
            }
        }
    }
}
