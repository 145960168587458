.resume-container {
    display: flex;
    flex-direction: column;
    padding: $base-padding;
    background-color: $white;

    .cv-listing {
        color: $black;
    }

    .resume-section-title {
        display: flex;
        flex-direction: column;

        button {
            background-color: transparent;
            border: none;
            padding: 0;
            margin: 0;
            color: $black;
            margin-left: auto;
            cursor: pointer;
        }

        .expand-icon {
            height: 2rem;
            width: auto;
            margin-left: auto;
        }

        p {
            color: $black;
            align-self: flex-start;
            margin: 0;
            padding: 1rem 0;
            margin-right: auto;
        }
    }
    .divider {
        border-top: 1px solid $black;
        width: 100%;
        height: 0.1rem;
        height: 20px;
        margin-bottom: 1rem;
    }
}

@media (min-width: 950px) {
    .resume-container {
        flex-direction: row;
        padding-left: 7%;

        .resume-section-title {
            flex-direction: column;
            flex: 1;
            padding: 1rem;
        }
    }
    .cv-listing {
        padding-left: 2rem;
    }
}
