.section-divider {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: $base-padding;

    .black {
        color: $black;
    }

    .white {
        color: $white;
    }

    .secondary-dark {
        color: $secondary-dark;
    }

    h2 {
        font-size: $paragraph-medium;
        font-family: Raleway-Regular;
        font-weight: 400;
        width: 40%;
        line-height: 20px;
    }

    .divider {
        width: 60%;
        margin-right: 10%;
    }
    .divider-secondary-dark {
        border-bottom: 1px solid $secondary-dark;
    }

    .divider-black {
        border-bottom: 1px solid $black;
    }

    .divider-white {
        border-bottom: 1px solid $white;
    }
}
