.mobile-header {
    visibility: visible;
    display: flex;
    align-items: center;
    position: fixed;
    justify-content: center;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    background-color: $bg-color;
    z-index: 200;

    .left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;
        a {
            color: $secondary-dark;
            text-transform: none;
        }
    }

    button {
        background-color: transparent;
        border: none;
        margin: 0;
        margin-left: auto;
        cursor: pointer;
    }

    .icon {
        height: 24px;
        width: auto;
        color: $secondary-dark;
        padding: $base-padding;
    }
}

@media (min-width: 950px) {
    .mobile-header {
        visibility: hidden;
    }
}
