.project-slider {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: $base-padding;
    background-color: $bg-color;

    button {
        position: absolute;
        top: 40%;
        cursor: pointer;
        background-color: $primary;
        height: 35px;
        width: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 100;
        border: none;
        &:focus {
            background-color: $primary;
        }
    }

    .buttonRight {
        color: $primary-dark;
        right: 0;
        box-shadow: $shadow 0px 2px 8px 0px;
    }

    .buttonLeft {
        color: $primary-dark;
        left: 0;
        box-shadow: $shadow 0px 2px 8px 0px;
    }

    .project-section-heading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 1rem;

        span {
            border-bottom: 1px solid $light-grey;
            width: 100%;
            margin: 0 $base-padding;
        }
    }

    .slider-container {
        position: relative;
        width: 95%;
        height: 90dvh;
        background-color: $white;
        box-shadow: $shadow 0px 2px 8px 0px;
        padding: $base-padding;

        ul {
            list-style: none;
            height: 100%;
        }
    }
}

@media (min-width: 950px) {
    .project-slider {
        button {
            top: 45%;
        }
        .slider-container {
            height: 100%;
        }
    }
}
