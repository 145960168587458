.skills-list-container {
    margin: 1rem;
    height: 100%;

    ul {
        display: flex;
        flex-wrap: wrap;
        border-left: 2px solid $primary-dark;
    }
}

.skills-list-title {
    display: flex;
    align-content: center;
    padding: 0.5rem 0;
    color: $black;

    h4 {
        white-space: nowrap;
        font-size: 20px;
    }
}
