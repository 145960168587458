@import './assets/styles/variables';
@import './assets/styles/base';

//components
@import './assets/styles/components/sectionDivider';
@import './assets/styles/components/indexList';
@import './assets/styles/components/outlinedButton';
@import './assets/styles/components/filledButton';
@import './assets/styles/components/resume';
@import './assets/styles/components/hero';
@import './assets/styles/components/mobileDrawer';
@import './assets/styles/components/mobileHeader';
@import './assets/styles/components/bio';
@import './assets/styles/components/projectSlider';
@import './assets/styles/components/skills';
@import './assets/styles/components/projectListItem';
@import './assets/styles/components/sectionTitle';
@import './assets/styles/components/footer';
@import './assets/styles/components/sideBar';
@import './assets/styles/components/_desktopHeader';
@import './assets/styles/components/_skillsList.scss';

@font-face {
    font-family: 'Raleway-Regular';
    src: url('./assets/fonts/Raleway/Raleway-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Radley-Regular';
    src: url('./assets/fonts/Radley/Radley-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Pontano-sans';
    src: url('./assets/fonts/Pontano_Sans/PontanoSans-VariableFont_wght.ttf') format('truetype');
}
