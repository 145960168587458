//resets

body {
    box-sizing: border-box;
    font-family: pontano-sans, serif;
}

html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
html,
body {
    margin: 0;
    padding: 0;
}

button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

//theme-typography

.heading-one-regular {
    font-family: pontano-sans, sans-serif;
    font-size: 3rem;
    font-weight: 100;
}

.heading-two-regular {
    font-family: pontano-sans, sans-serif;
    font-size: 2.4rem;
    font-weight: 100;
}

.heading-three-regular {
    font-family: pontano-sans, sans-serif;
    font-size: 1.75rem;
    font-weight: 100;
}

.heading-four-regular {
    font-family: pontano-sans, sans-serif;
    font-size: 1.1rem;
    text-transform: uppercase;
    font-weight: 100;
}

.heading-five-regular {
    font-family: pontano-sans, sans-serif;
    font-size: 0.75rem;
    text-transform: uppercase;
    font-weight: 100;
}

.heading-six-regular {
    font-family: pontano-sans, sans-serif;
    font-size: 0.9rem;
    font-weight: 100;
}

.paragraph-regular {
    font-family: kumbh sans, sans-serif;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.6rem;
    letter-spacing: 1.5px;
}
