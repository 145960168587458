.section-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 1rem 0.5rem;
    color: $black;
    background-color: $white;

    p {
        margin-right: 5px;
    }

    span {
        border-bottom: 1px solid $black;
        width: 100%;
        margin-left: 20px;
        margin-bottom: 10px;
    }
}
