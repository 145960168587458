//colors
$bg-color: #f7f4f2;
$primary: #f0ff3d;
$primary-dark: #fc3393;
$secondary-dark: #003d31;
$secondary: #c0d8d7;

$disabled: #eef2ff;
$black: black;
$white: white;
$shadow: #63636333;
$light-grey: #d3d3d3;

$base-padding: 1rem;
$base-border: 1px solid white;
$base-border-radius: 4px;
$base-margin: 6px;

//font-sizes
$base-font-size: 1rem;
$heading-one-size: calc($base-font-size * 3.25);
$heading-two-size: calc($base-font-size * 2);
$heading-three-size: calc($base-font-size * 1.25);
$paragraph-normal: $base-font-size;
$paragraph-small: calc($base-font-size / 1.33);
$paragraph-medium: calc($base-font-size / 1.15);
