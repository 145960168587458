.footer-wrapper {
    display: flex;
    color: $white;
    justify-content: center;
    padding: $base-padding;
    flex-wrap: wrap;
    width: 100%;
    background-color: $white;

    .column-one {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .column-two {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: 2rem;

        .top-right-item {
            visibility: hidden;
        }

        .bottom-right-list {
            margin-left: auto;
            li {
                margin: 1rem;
                a {
                    color: $secondary-dark;
                }
            }
        }
    }

    .menu-items {
        padding-left: calc($base-padding * 3);

        li {
            display: flex;

            .left-list-space {
                width: 25%;
            }

            .list-item {
                display: flex;

                p {
                    padding: 0.5rem;
                }
            }
        }

        p {
            padding: 0;
            margin: 0;
        }
    }
}

@media (min-width: 950px) {
    .footer-wrapper {
        .column-one {
            width: 0;
        }
        .column-two {
            width: 100%;

            .top-right-item {
                visibility: visible;
                align-self: flex-start;
                margin-left: auto;
                color: $primary-dark;
            }

            .bottom-right-list {
                margin-top: 3rem;

                li {
                    margin: 1rem;
                    a {
                        color: $secondary-dark;
                    }
                }
            }
        }

        .menu-items {
            li {
                .left-list-space {
                    width: 30%;
                }
            }
        }
    }
}
