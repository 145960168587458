.project-list-item {
    display: flex;
    flex-direction: column;
    height: 100%;

    .project-item-title {
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        margin-right: auto;
        width: 100%;
        padding-bottom: 2rem;

        p {
            margin-right: 5px;
        }

        h2 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }

        span {
            border-bottom: 1px solid black;
            width: 100%;
            margin-left: 5px;
            margin-bottom: 10px;
        }
    }

    .demo-container {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: auto;
        padding: 1rem 2rem;
        overflow: hidden;
    }

    .text-content {
        flex: 4;
        overflow: scroll;
        display: flex;
        flex-direction: column;

        .external-links {
            display: flex;
            flex-direction: column;

            a {
                font-size: 16px;
                color: $primary-dark;
                padding: calc($base-padding / 3);
            }
        }

        .project-technologies-list {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            padding: 1.5rem 0;

            span {
                height: 12px;
                border-left: 1px solid $secondary-dark;
                padding: 0.2rem;
                margin: 3px;
            }

            h3 {
                color: $secondary-dark;
            }

            ul {
                display: flex;
                padding: 0;

                li {
                    padding: 0 0.5rem;
                    color: $black;
                }
            }
        }

        .project-details {
            display: flex;
            flex-direction: column;

            .description {
                overflow: scroll;
            }

            .project-details-title {
                display: flex;
                align-items: center;
                color: $secondary-dark;

                span {
                    height: 12px;
                    border-left: 1px solid $secondary-dark;
                    padding: 0.2rem;
                    margin: 3px;
                }
            }

            .project-tech-and-links {
                display: flex;
                flex-direction: column;
            }
        }
    }
}

@media (min-width: 950px) {
    .project-item-title {
        h2 {
            min-width: 200px;
            max-width: 250px;
        }
    }
    .project-list-item {
        justify-content: center;
        align-items: center;
        margin: auto;
        overflow: hidden;

        .demo-container {
            flex: 1;
            padding-bottom: 1rem;

            .video-player {
                width: 100%;
            }
        }
        .text-content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row-reverse;
            overflow: hidden;
            width: 100%;
            max-width: 1200px;

            .project-details {
                padding: 2rem;
                width: 60%;
                .description {
                    overflow: auto;
                }
            }

            .project-tech-and-links {
                display: flex;
                flex-direction: column-reverse;
                width: 40%;
                padding: 2rem;
            }
            .project-technologies-list {
                padding: 0;
                justify-content: flex-start;
            }
        }
    }
}
