.bio-section {
    height: 100%;

    .extra-space-span {
        width: 100%;
        height: 59px;
        background-color: #5c5f6a;
    }

    .bio-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: auto;
        background-color: $white;

        .text-section {
            padding: 2rem;
        }

        .project-link-wrapper {
            display: flex;
            justify-content: flex-end;
            padding: $base-padding;

            a {
                background-color: $primary;
                padding: calc($base-padding / 1.5);
                color: $secondary-dark;
                box-shadow: $shadow 0px 2px 8px 0px;
            }
        }

        .img-container {
            padding: 1rem 0;
            img {
                object-fit: cover;
                width: 100%;
                max-height: 500px;
                min-height: 200px;
                height: auto;
            }
        }
    }
}

@media (min-width: 950px) {
    .bio-section {
        align-items: center;

        .extra-space-span {
            display: none;
        }

        .bio-wrapper {
            display: flex;
            flex-direction: row-reverse;
            flex: 1;
            padding-left: 7%;

            img {
                height: auto;
                max-height: 700px;
                max-width: 100%;
                flex: 1;
                // margin: 7rem 1rem;
            }

            .text-section {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 1rem;
            }
        }
    }
}
