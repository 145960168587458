.skills-section {
    background-color: #fff;
    width: 100%;

    .sub-title-text {
        padding: 0.2rem 1rem;
    }

    .skills-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

@media (min-width: 950px) {
    .skills-section {
        .sub-title-text {
            padding: 0.2rem 7rem;
        }
        .skills-wrapper {
            flex-direction: row;
            flex-wrap: wrap;
            padding-left: 7rem;
            max-width: 90%;
        }
    }
}
