.filled-button {
    border: none;
    border-radius: $base-border-radius;
    color: white;
    padding: $base-padding - 0.5;
    margin: $base-margin;
    width: 100%;

    .secondary {
        background-color: $secondary;
    }
}

.filled-button-primary {
    background-color: $primary;
}
